html {
  background-color: #E0DEDA;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
}

html:active {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
}

.yarl__portal_open {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
}

#root {
  overflow: hidden;
}

#main, #gallery-container, #item-gallery {
  width: 75%;
  margin: auto;
}

#main section {
  display: flex;
  margin-bottom: 3rem;
  animation: fadeIn 600ms ease-in-out;
}
#main section .form-wrapper {
  display: flex;
  margin-top: 12rem;
  width: 100%;
  justify-content: center;
}
#main section .form-foto {
  margin: auto;
}
#main section .form-foto img {
  width: 350px;
  height: 500px;
}
#main section form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 60%;
}
#main section form label {
  position: relative;
  padding: 1rem;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
}
#main section form label:nth-child(9) {
  grid-column: span 2;
}
#main section form label .error-message {
  display: none;
  font-weight: 400;
}
#main section form label .error-message.show {
  display: block;
}
#main section form label input, #main section form label textarea {
  background-color: #E0DEDA;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
  border: none;
  border-bottom: 1px solid black;
  margin-top: 1rem;
  width: 100%;
}
#main section form label input.error, #main section form label textarea.error {
  border-bottom: 2px solid white;
}
#main section form label input:focus, #main section form label textarea:focus {
  outline: none;
}
#main section form label textarea {
  margin-top: 2rem;
}
#main section form .submit-container {
  grid-column: span 2;
  width: 100%;
  text-align: end;
}
#main section form .submit-btn {
  border: none;
  background-color: transparent;
  width: fit-content;
  font-size: 22px;
  margin-top: 2rem;
}
#main section form .submit-btn:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
  color: white;
  transition: color 150ms ease-in-out;
}
#main #contact {
  margin-top: -9rem;
}
#main #agence {
  flex-direction: column;
  margin-top: -3rem;
}
#main #agence article {
  width: 100%;
  margin: auto;
}
#main #agence article h2 {
  margin-top: 0;
  margin: auto auto 1rem auto;
  font-size: 25px;
  font-weight: 100;
}
#main #agence article h3 {
  margin: auto auto 2rem 2rem;
  max-width: 90%;
  font-size: 25px;
  font-weight: 100;
}
#main #agence .agence-article {
  display: flex;
}
#main #agence .agence-article .nav-wrapper .line-2:nth-child(1) {
  height: 20%;
}
#main #agence .agence-article .nav-wrapper .internal-link {
  margin: 0;
}
#main #agence .agence-article .description {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}
#main #agence .agence-article .description .text .maison-section .maison-parag {
  padding-inline-start: 1.5rem;
}
#main #agence .agence-article .description .text .maison-section .maison-parag li {
  color: white;
  font-size: 0.9rem;
}
#main #agence .agence-article .description .text .maison-section .maison-text {
  text-align: justify;
  letter-spacing: 1.2px;
  font-size: 0.7rem;
  line-height: 25px;
}
#main #agence .agence-article .description figure {
  margin: 0.5rem 0 0 0;
  width: 40%;
}
#main #agence .agence-article .description figure img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
#main #agence .agence-article .steps {
  margin-top: 5rem;
}
#main #agence .agence-article .steps .steps-description {
  margin: auto auto 5rem 2rem;
  width: 90%;
  white-space: pre-wrap;
}
#main #agence .agence-article .steps .steps-description span {
  display: block;
  margin-bottom: 0.6rem;
  letter-spacing: 1.2px;
  font-size: 0.7rem;
}
#main #agence .agence-article .steps .quotes-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 200px;
  margin: 7rem auto auto auto;
  text-align: center;
}
#main #agence .agence-article .steps .quotes-container .controls-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
#main #agence .agence-article .steps .quotes-container .controls-container .control {
  display: inline-block;
  margin: 0.6rem;
  width: 8px;
  height: 8px;
  border: 1px solid black;
  border-radius: 20px;
  background-color: #000000;
}
#main #agence .agence-article .steps .quotes-container .controls-container .control.active {
  transform: scale(1.4);
  background-color: #E0DEDA;
}
#main #agence .agence-article .steps .quotes-container h4 {
  width: 80%;
  margin: auto;
}
#main #agence .agence-article .steps .quotes-container .quote {
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
#main #agence .agence-article .steps .quotes-container .quote.active {
  opacity: 1;
}
#main #agence .agence-article .steps .quotes-container .quote .quote-text {
  font-style: italic;
  font-size: 0.7rem;
  width: 80%;
  margin: auto;
  padding: 2.5rem 0 2.5rem 0;
}
#main #agence .agence-article .steps .quotes-container .quote-icon:nth-child(n+2) {
  align-self: flex-end;
  text-align: end;
}
#main #agence .agence-article .steps .quotes-container .quote-icon {
  width: 9%;
  height: 9%;
}
#main #agence .agence-article .steps .quotes-container .quote-icon img {
  width: 100%;
  height: 100%;
}
#main #agence .agence-article .steps .card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}
#main #agence .agence-article .steps .card-wrapper .card-container {
  margin: 1rem auto 1rem auto;
}
#main #agence .agence-article .steps .card-wrapper .arrow {
  font-size: xx-large;
}
#main #agence .agence-article .text {
  width: 50%;
  font-size: 0.8rem;
  padding: 0 2rem 2rem 2rem;
}
#main #agence .agence-article .text .article-head {
  text-align: end;
}

.loader {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 9999;
}
.loader img {
  max-width: 500px;
  animation: fadeIn 1.5s ease-in-out;
}

.gallery-loader {
  min-height: 800px;
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: auto;
  width: 76%;
}
header .header-sub {
  position: absolute;
  font-size: 10px;
  top: 6rem;
  right: 0;
}
header .mobile-toggle {
  visibility: hidden;
  position: absolute;
  top: 2rem;
  right: 0;
  background: none;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
  border: none;
  padding: 10px;
}
header .mobile-toggle.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}
header .mobile-toggle.open .bar:nth-child(2) {
  opacity: 0;
}
header .mobile-toggle.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}
header .mobile-toggle .bar {
  display: block;
  width: 25px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.634);
  margin: 5px 0;
  transition: 0.4s;
}
header .mobile-nav {
  position: absolute;
  top: 6rem;
  height: 100vh;
  margin: auto;
  background-color: #E0DEDA;
  opacity: 0;
  z-index: -1;
}
header .mobile-nav.show {
  opacity: 1;
  animation: fadeIn 400ms ease-in-out;
  z-index: 10;
}
header .mobile-nav ul {
  width: fit-content;
  margin: auto;
  list-style-type: none;
}
header .mobile-nav ul li {
  margin-right: 1rem;
  padding: 2rem;
}
header .logo-container {
  width: fit-content;
  position: relative;
  margin-left: -5.2rem;
}
header .logo-container a {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
  width: fit-content;
}

#auben-logo {
  width: min-content;
  text-align: center;
  margin: 1rem 0 0 2rem;
}
#auben-logo img {
  max-width: 170px;
}
#auben-logo figcaption {
  font-size: 8px;
  width: 85%;
  letter-spacing: 2px;
  margin: -1rem auto auto 1.1rem;
}

#contact .internal-link .section-title {
  padding: 2rem 0rem 8rem 0.9rem;
}

#agence .internal-link .section-title {
  padding: 2rem 0rem 9rem 0.9rem;
}

.nav-wrapper {
  position: relative;
  width: fit-content;
}
.nav-wrapper .gallery-nav .internal-link, .nav-wrapper .item-nav .internal-link {
  margin-top: 2.4rem;
}

.nav-wrapper {
  position: relative;
  width: fit-content;
}
.nav-wrapper .internal-link {
  margin-top: 3.2rem;
}
.nav-wrapper .section-title {
  margin-left: -2.5rem;
  width: max-content;
  letter-spacing: 1rem;
  font-size: 26px;
  font-weight: 100;
  z-index: 9999;
}
.nav-wrapper .line-1::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.8px;
  height: 98px;
  background-color: black;
}
.nav-wrapper .line-2 {
  height: 100%;
  width: 60%;
  text-align: center;
  padding: 1rem 0rem 1.5rem 0;
}
.nav-wrapper .line-2::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.8px;
  height: 100%;
  background-color: black;
}
.nav-wrapper .line-1 {
  text-align: center;
  width: 70%;
  padding: 0;
}
.nav-wrapper .line-1.hidden {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}
a :hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
}

.internal-link {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  max-width: 100px;
  height: 100%;
}
.internal-link .projet-link {
  margin: auto;
  font-size: 10px;
}
.internal-link .projet-link a:hover {
  color: white;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
  transition: color 250ms ease-out;
}
.internal-link .section-title, .internal-link li:not(.item):not(.line-1):not(.line-2) {
  text-align: center;
  width: max-content;
  padding: 2.1rem 0rem 3.4rem 0.5rem;
  transform: rotate(-90deg);
}
.internal-link .nav-hidden {
  display: none;
}
.internal-link .item {
  font-size: large;
  text-align: center;
  padding: 0.7rem 1.2rem 0 0;
}

.step-list {
  font-size: 0.7rem;
  padding: 0.5rem;
  list-style-type: "-";
}

#home {
  display: flex;
  position: relative;
  margin-top: -1.2rem;
}

.item {
  display: inline-block;
}
.item:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
  transform: scale(1.3);
  transition: transform 250ms ease-in-out;
}

.carousel-container {
  position: relative;
  margin: 0 auto auto 2rem;
  width: 100%;
  height: fit-content;
}
.carousel-container:hover .btn-container .carousel-btn {
  opacity: 1;
  transform: scale(1.1);
  transition: transform, opacity 250ms ease-in-out;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
}
.carousel-container .carousel .control-dots {
  display: none;
}
.carousel-container .btn-container {
  position: absolute;
  width: 100%;
  bottom: 2rem;
  text-align: center;
}
.carousel-container .btn-container .carousel-btn {
  padding: 1rem 2rem 1rem 2rem;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  opacity: 0;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide .carousel-wrapper {
  width: 100%;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide .carousel-wrapper img {
  height: 550px;
  object-fit: cover;
  object-position: 65% 35%;
}

.line-3 {
  width: 72%;
  height: 50%;
  text-align: center;
}
.line-3::after {
  content: "";
  display: inline-block;
  background-color: black;
  height: 100%;
  width: 0.8px;
}

#gallery-container {
  display: flex;
}
#gallery-container .gallery-items {
  width: 100%;
  margin-top: -1rem;
}
#gallery-container h5 {
  font-weight: 300;
  font-size: 0.8rem;
  margin-top: -1.7rem;
}
#gallery-container h5 span {
  padding: 0.5rem;
  line-height: 1px;
  font-size: large;
}
#gallery-container .gallery {
  float: right;
  margin-bottom: 6rem;
  animation: fadeIn 600ms ease-in-out;
  width: 91%;
}
#gallery-container .gallery-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
}
#gallery-container .gallery-wrapper .card {
  margin: 0.2rem;
  width: 97%;
  min-height: 215px;
}
#gallery-container .gallery-wrapper .card:hover {
  transform: scale(1.05);
  transition: transform 250ms ease-in-out;
}
#gallery-container .gallery-wrapper .card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#item-gallery {
  display: flex;
  animation: fadeIn 600ms ease-in-out;
}
#item-gallery .item-title {
  font-weight: 200;
  font-size: 10px;
  text-align: end;
  margin-top: 5.1rem;
  margin-right: 1.1rem;
}
#item-gallery .item-container {
  display: flex;
  flex-wrap: wrap;
}
#item-gallery .item-container figure:nth-child(odd) {
  width: 32%;
}
#item-gallery .item-container figure:nth-child(3) {
  width: 65%;
}
#item-gallery .item-container figure:nth-child(4) {
  width: 32%;
}
#item-gallery .item-container figure {
  margin: 0 0.2rem 0.4rem 0.2rem;
  width: 65%;
  height: auto;
}
#item-gallery .item-container figure img {
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 450px;
  object-fit: cover;
}
#item-gallery section {
  margin: -3.5rem auto auto auto;
}

#auben-footer {
  position: absolute;
  margin: 4rem 0 0 0;
  right: 0.4rem;
}
#auben-footer img {
  transform: rotate(-90deg);
  width: 160px;
}

footer {
  background-color: #E0DEDA;
  margin-top: 6.5rem;
  width: 100%;
  height: 200px;
  margin-bottom: 5rem;
  font-size: 0.6rem;
}
footer .footer-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: fit-content;
  margin: auto;
}
footer .footer-container .footer-mail, footer .footer-container .mentions {
  padding: 0.5rem;
}
footer .footer-container .item {
  padding: 0.5rem;
  font-size: 1.2rem;
}

#mentions-legales {
  width: 80%;
  margin: auto;
  margin-top: 3rem;
}
#mentions-legales h2 {
  text-decoration: underline;
}

@media screen and (max-width: 1255px) {
  header figcaption {
    visibility: hidden;
  }
  #agence .agence-article .arrow {
    visibility: hidden;
  }
}
@media screen and (max-width: 1050px) {
  #main .form-foto {
    display: none;
  }
  #main #agence .agence-article .text .article-head {
    text-align: center;
  }
  #main #agence .agence-article .steps .quotes-container {
    height: 250px;
  }
}
@media screen and (max-width: 998px) {
  header {
    width: 95%;
  }
  header .logo-container {
    margin-left: 1rem;
  }
  header .logo-container #auben-logo img {
    max-width: 120px;
  }
  .carousel-container {
    margin: 0;
  }
  #item-gallery {
    width: 95%;
    margin-top: 2rem;
  }
  #item-gallery .item-nav {
    margin-top: -3rem;
  }
  #item-gallery .item-title {
    margin-top: 1.5rem;
  }
  #gallery-container {
    width: 95%;
    margin-top: 2rem;
  }
  #gallery-container .gallery {
    width: 100%;
  }
  #gallery-container .gallery-wrapper {
    grid-template-columns: auto auto;
  }
  #main {
    margin-top: 2rem;
  }
  #main section form {
    display: flex;
    flex-direction: column;
  }
  #main #agence .agence-article .description {
    flex-direction: column-reverse;
    align-items: center;
  }
  #main #agence .agence-article .description figure {
    width: 100%;
  }
  #main #agence .agence-article .text {
    text-align: center;
    width: 90%;
  }
  #main #agence .agence-article .text h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  #main {
    width: 95%;
  }
  #main section {
    margin-top: 0;
    width: 100%;
  }
  #item-gallery .item-container figure {
    width: 100%;
  }
  #item-gallery .item-container figure:nth-child(4) {
    width: 100%;
  }
  #item-gallery .item-container figure:nth-child(odd) {
    width: 100%;
  }
  .card {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .header-sub {
    display: none;
  }
  header .mobile-nav {
    width: 100%;
  }
  header .logo-container {
    margin-left: 0;
  }
  header .mobile-toggle {
    visibility: visible;
  }
  header figure img {
    height: 5rem;
    width: 7rem;
  }
  .nav-wrapper {
    display: flex;
    flex-direction: column;
  }
  .nav-wrapper .line-1.hidden {
    display: block;
  }
  .nav-wrapper .line-2 {
    width: 50%;
  }
  .nav-wrapper nav .internal-link li:not(.item, .line-1) {
    display: none;
  }
  .nav-wrapper nav .internal-link li:nth-child(2) {
    display: block;
    width: 100%;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide .carousel-wrapper img {
    height: 520px;
  }
  .carousel-counter {
    display: none;
  }
  .carousel-controls {
    transform: translateY(-500%);
  }
  #main section .form-wrapper {
    margin-left: -5rem;
  }
  #main #agence {
    margin-bottom: 1rem;
  }
  #main #agence .internal-link .section-title {
    padding: 2rem 0rem 9.5rem 0.9rem;
  }
  #main #agence .agence-article .steps .card-wrapper {
    flex-wrap: wrap;
  }
  #main #agence .agence-article .steps .quotes-container {
    width: 100%;
  }
  #main #contact .internal-link .section-title {
    padding: 2rem 0rem 8.5rem 0.9rem;
  }
  #gallery-container h5 {
    margin: auto;
  }
  #auben-footer {
    display: none;
  }
}
@media screen and (max-width: 666px) {
  #auben-footer {
    display: none;
  }
  .gallery-wrapper .card:hover {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z'/%3E%3C/svg%3E") 5 5, auto;
    transform: scale(1.05);
    transition: transform 250ms ease-in-out;
  }
  .gallery-wrapper .card img {
    object-fit: cover;
    width: 100%;
    height: 270px;
  }
  #item-gallery .item-container {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media screen and (max-width: 650px) {
  header {
    width: 100%;
  }
  #home .nav-wrapper {
    width: fit-content;
    margin-bottom: 5rem;
  }
  .internal-link {
    width: 50%;
  }
  .internal-link .nav-hidden {
    margin-left: -1rem;
  }
  .internal-link .item.facebook {
    padding: 1rem 0.6rem 0 0;
  }
  #main {
    width: 95%;
  }
  #main #contact .internal-link .section-title {
    padding: 2rem 0rem 9rem 0.9rem;
  }
  #main #agence {
    margin-top: 3rem;
  }
  #main #agence .agence-article .steps {
    margin-left: -1.2rem;
  }
  #main #agence .agence-article .steps .card-wrapper {
    margin-left: -4rem;
  }
  #main #agence .agence-article .steps .quotes-container {
    width: 100%;
  }
  #main #agence .internal-link .section-title {
    padding: 2rem 0rem 10rem 0.9rem;
  }
  #main #agence .agence-article {
    margin-top: -3rem;
  }
  #main #agence .agence-article .steps h3 {
    width: 100%;
    margin-left: 0;
  }
  #main #agence .agence-article .steps .steps-description {
    margin-left: 0;
    width: 100%;
  }
  #main #agence .agence-article .description {
    margin-left: -3.5rem;
  }
  .carousel-container {
    margin: 0;
    margin-left: -1rem;
  }
  #agence .agence-article, #contact .agence-article {
    margin-top: -8rem;
  }
  #agence .section-title, #contact .section-title {
    font-size: 1.1rem;
    margin-left: -2rem;
  }
  .line-3 {
    width: 30%;
  }
}
@media screen and (max-width: 450px) {
  #home .nav-wrapper {
    margin-top: -2.5rem;
  }
  .carousel-container .btn-container {
    display: none;
  }
  #main #agence .agence-article .steps .quotes-container {
    height: 400px;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide .carousel-wrapper img {
    height: 400px;
  }
  .carousel-controls {
    transform: translateY(-350%);
    width: 95%;
  }
  .carousel-controls img {
    width: 20px;
  }
  #gallery-container .gallery-wrapper {
    grid-template-columns: auto;
  }
  #main #agence .agence-article .nav-wrapper .internal-link {
    margin-top: 2rem;
  }
  #main #agence {
    margin-top: -4rem;
  }
  #main #agence .agence-article .description {
    justify-content: center;
  }
  #main #agence .agence-article .description img {
    width: 100%;
    height: auto;
  }
  #main #agence .internal-link .item {
    margin-left: 0.3rem;
  }
  #main #agence .agence-article .steps .quotes-container {
    margin-left: -1rem;
  }
}
@media screen and (max-width: 360px) {
  #main section .form-wrapper {
    margin-left: -3rem;
  }
  #agence .agence-article, #contact .agence-article {
    margin-top: -8rem;
  }
  #main #agence .internal-link .section-title {
    padding: 2rem 0rem 10.5rem 0.9rem;
  }
  #main #contact .internal-link .section-title {
    padding: 2rem 0rem 9.4rem 0.9rem;
  }
  #main #agence .agence-article .description img {
    width: 90%;
  }
  .nav-wrapper {
    width: 20%;
  }
  #main #agence .agence-article .steps {
    margin-left: -0.5rem;
  }
  .carousel .slider-wrapper.axis-horizontal .slider .slide .carousel-wrapper img {
    height: 300px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

